
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --backgroud-color:var(--color-bg);
  --font-family: 'Manrope', sans-serif;
  --color-bg: #ffffff;
  --color-footer : #1E1E1E;
  --color-bl:#1d1d1d;
  --color-text: #00962F;
  --color-button: #57E7A5;
  --color-hover:#00962F;
}


