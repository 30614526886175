.pbes_footer {
    height: fit-content;
    max-width: -webkit-fill-available;
    background-color: #192227;
}

.pbes_footer_items img {
    max-width: 50%;

}


.pbes_footer_items .right {
    float: right;
    margin: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.pbes_footer_items .right .btn {
  
    float: right;
    background-color: var(--color-button);
    font-size: 24px;
    font-weight: bold;
    max-width: 50px;
    text-align: center;
    padding: 8px;
}

sp {
    color: var(--color-button);
    margin-right: 20px;
    font-family: var(--font-family);
}

.pbes_footer .copy {
    text-align: center;
    margin: 0;
    font-family: var(--font-family);
}