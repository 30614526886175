.pbes__service {
    height: 100vh;
    padding-top: 50px;

}

.pbes__service-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pbes__service-heading_topic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.pbes__service-heading_topic h5 {
    font-family: var(--font-family);
    line-height: 24px;
    letter-spacing: 12px;
    color: var(--color-text);
    margin-bottom: 20px;
}

.pbes__service-heading_topic h1 {
    font-family: var(--font-family);
    line-height: 50px;
    font-size: 40px;
    Word-spacing: 10px;
    color: #1d1d1d;
    margin: 0;
}

.pbes__service-items {
    margin-left: auto;
    max-width: 90%;
}

.pbes__service-items_groupA {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.pbes__service-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    max-width: 27%;

}

.pbes__service-first img {
    background: white;
    border-radius: 5%;
    padding: 5px;
}

.pbes__service-first h4 {
    font-family: var(--font-family);
    margin: 0px 0px 0px 8px;
    color: var(--color-text);
    font-weight: bold;
    font-size: 22px;
}

.pbes__service-first p {
    font-family: var(--font-family);
    margin: 0px 0px 0px 8px;
    font-size: 14px;
    text-align: center;
    color: #3a3a3a;
    text-align: justify;

}

@media screen and (max-width:1024px) {
    .pbes__service {
        height: fit-content;
        
    }
    .pbes__service-heading_topic h5 {
        line-height: 18px;
        font-size: 14px;
        letter-spacing: 10px;
        margin: 0;
        text-align: center;
    }

    .pbes__service-heading_topic h1 {
        line-height: 36px;
        font-size: 26px;
        Word-spacing: 8px;
        text-align: center;
    }

    .pbes__service-first h4 {
        font-size: 14px;
    }

    .pbes__service-first p {
        margin: 0px 0px 0px 8px;
        font-size: 10px;
        text-align: left;
    }

    .pbes__service-first {
        padding: 8px;
    }
}


@media screen and (max-width:880px)
{
    .pbes__service-items_groupA {
      justify-content: space-between;
    }
    .pbes__service-first {
   
        max-width: 50%;
    
    }
    .pbes__service-heading_topic h5 {
        line-height: 18px;
        font-size: 14px;
        letter-spacing: 7px;
        margin: 0;
        text-align: center;
    }

    .pbes__service-heading_topic h1 {
        line-height: 36px;
        font-size: 26px;
        Word-spacing: 8px;
        text-align: center;
    }
    .pbes__service-items {
        margin-left: 0;
        max-width: 90%;
    }
}


@media screen and (max-width:600px)
{
    .pbes__service-items_groupA {
      justify-content: space-between;
    }

    .pbes__service-heading_topic h5 {
        line-height: 18px;
        font-size: 12px;
        letter-spacing: 4px;
    }

    .pbes__service-heading_topic h1 {
        line-height: 28px;
        font-size: 20px;
        Word-spacing: 6px;
        text-align: center;
    }
}
@media screen and (max-width:400px)
{
    .pbes__service-items_groupA {
      justify-content: space-between;
      flex-direction: column;
    }

    .pbes__service-heading_topic h5 {
        line-height: 18px;
        font-size: 12px;
        letter-spacing: 6px;
    }
  
    .pbes__service-heading_topic h1 {
        line-height: 28px;
        font-size: 20px;
        Word-spacing: 6px;
        text-align: center;
    }
    .pbes__service-first {
   
        max-width: 100%;
    
    }
    .pbes__service {
        height: fit-content;
        padding-top: 50px;
    
    }
}