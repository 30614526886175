
*
{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    
  }
  body
  {
    background-color: white;
  }
.Head
{
  height: 100vh;
  background-image: url("C:\Software Engineer\PBES\root\pbes\src\assets\bg-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(4px);
  
}
.Nav
{
  position: absolute;
  width: 100%;
  top:0
}
.Why
{
  background-image: url("C:\Software Engineer\PBES\root\pbes\src\assets\bg-why.jpg");
  background-repeat: no-repeat;
  background-size: cover;

}

a
{
    color: unset;
    text-decoration: none;
}
.section__padding
{
    padding: 1rem 2.5rem;
}
.section__margin
{
    margin: 4rem 6rem;
}
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width:700px) 
  {
    .section__padding
{
    padding: 4rem;
}
.section__margin
{
    margin: 4rem;
}
  }
  @media screen and (max-width:550px) 
  {
    .section__padding
{
    padding: 4rem 2rem;
}
.section__margin
{
    margin: 4rem 2rem;
}
  }