.pbes_contact
{   
    background-image: url("C:\Software Engineer\PBES\root\pbes\src\assets\side.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    max-width: -webkit-fill-available;
}
.pbes_contact_content
{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 100px;
   
}
.pbes_contact_content_items 
{   
    text-align: left;
}
.pbes_contact_content_items p
{
    margin: 0;
    color:var(--color-text);
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    
}
.pbes_contact_content_items h3
{
    margin: 0;
    color:#1d1d1d;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 34px;
}
.pbes_contact_content_items_info
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
}
.pbes_contact_content_items_info .info
{
    
    margin-top: 40px;
}
.pbes_contact_content_items_info p
{
    color: #757575;
    font-size: 14px;
    margin-left: 50px;
}
.pbes_contact_content_items_info img
{
    width:30px;
    float: left;
}
.social-icons
{
    margin-top: 50px;
}
.social-icons img
{
    margin-right: 20px;
    padding-bottom: 8px;
}



@media screen and (max-width:1024px) {
    .pbes_contact_content
    {
        padding: 10px;
    }
}

@media screen and (max-width:880px) {
    .pbes_contact
    {
        background-image: none;
        height: fit-content;
    }
    .pbes_contact_content
    {
    
        justify-content: flex-start;
        align-items: flex-start;
        padding: 100px;
        background-color: aliceblue;
        
    }
}

