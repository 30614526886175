.pbes_advant {
   
    height: 100vh;
    max-width: -webkit-fill-available;
}

.pbes_advant-headings {
    text-align: center;
}

.pbes_advant-headings h5 {
    color: var(--color-text);
    letter-spacing: 8px;
    font-family: var(--font-family);
    margin-bottom: 5px;
}

.pbes_advant-headings h2 {
    color: #1d1d1d;
    letter-spacing: 5px;
    font-family: var(--font-family);
    margin: 0;
}

.pbes_advant_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.pbes_advant_items-content {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    width:1260px;
    margin: auto;
    flex-wrap: wrap;
}

.pbes_advant_items-content .pbes_container {
    display: flex;
    justify-content: center;
    margin-top:70px;
    align-items: center;
}
.pbes_advant_items-content .pbes_container img{
   float: left;
    width:50px;
}

.pbes_advant_items-content .pbes_container .pbes_container-headings {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.pbes_advant_items-content .pbes_container .pbes_container-headings h5 {
    font-size:16px;
    color:#1d1d1d;
    font-family: var(--font-family);
    margin: 0 10px;
}
.pbes_advant_items-content .pbes_container .pbes_container-headings p {
    font-size:12px;
    color:#1d1d1d;
    font-family: var(--font-family);
    margin: 0 10px;
    max-width: 350px;
}
.pbes_partner_content
{
    text-align: center;
    margin-top: 50px;
}
.pbes_partner_content h3
{
   color:#1d1d1d;
}
.pbes_partner_content h3::before
{
    content: "_____________________";
    color:var(--color-text);
    align-items: center;
  
}
.pbes_partner_content h3::after
{
    content: "_____________________";
    color:var(--color-text);
    align-items: center;
  
}

.pbes_partner_content_items img
{
    width: 150px;
    margin-right: 70px;
}
.pbes_partner_content_items p
{
    width: 200px;
    color: #1d1d1d;
    text-align: center;
    margin: 0;
}


@media  screen and (max-width:1024px) 
{
    
    .pbes_advant {
   
        height: fit-content;
    
    }
}

@media screen and (max-width:880px) {
    .pbes_advant_items-content 
    {
        justify-content: center;
    }
    .pbes_partner_content h3::after,.pbes_partner_content h3::before
    {
        display: none;
    }
}

@media screen and (max-width:700px)
{

    .pbes_advant-headings h2 {
        letter-spacing: 4px;
       font-size: 22px;
    }
}

