
.pbes__header-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
    width: 100%;
}

.pbes__header-items_headings {
    color: white;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    margin-right: 100px;
}

.pbes__header-items_headings h2 {
    line-height: 50px;
    letter-spacing: 15px;
    font-size: 30px;
    margin:0
}
.pbes__header-items_headings sp
{
    color: var(--color-button);
}

.pbes__header-items_headings h1 {
    line-height: 80px;
    font-size: 55px;
    margin-bottom: 5px;
}
.pbes__header-items_headings  p{
   color: #fff;
}

.pbes__header-items_btn {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.pbes__header-items_btn .Cart {
    background-color: var(--color-text);
    font-family: var(--font-family);
    color: white;
    width: 120px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-weight: bolder;
    margin-right: 10px;
    font-size: 15px;
    cursor: pointer;
}

.pbes__header-items_btn .Calc {
    font-family: var(--font-family);
    background-color: #CF744E;
    color: white;
    width: 120px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-weight: bolder;
    font-size: 15px;
    cursor: pointer;
}
.pbes__header-details
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.pbes__header-details_items
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex:1;
    padding: 50px;
    padding-bottom: 0;
    margin-top: 230px;
    
}
.pbes__header-details_items .project
{
    margin-right: 300px;
    background: white;
    border-radius: 5px 5px 0 0;
    width:200px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pbes__header-details_items .project img
{
  
    height: 50px;
    margin-right: 10px;
}
.pbes__header-details_items .project h4
{
    font-weight: bold;
    font-family: var(--font-family);
}
.pbes__header-details_items .project span
{
    font-weight: bold;
    font-family: var(--font-family);
    color:var(--color-text)
}
.pbes__header-details_items .project p
{   font-size: 12px;
    font-family: var(--font-family);
    font-weight: bold;
    color: #0000007a;
}



.pbes__header-details_items .project2
{
    margin-right: 300px;
    background: white;
    border-radius: 5px 5px 0 0;
    width:200px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pbes__header-details_items .project2 img
{

    height: 50px;
    margin-right: 10px;
}
.pbes__header-details_items .project2 h4
{
    font-weight: bold;
    font-family: var(--font-family);
}
.pbes__header-details_items .project2 span
{
    font-weight: bold;
    font-family: var(--font-family);
    color:var(--color-text)
}
.pbes__header-details_items .project2 p
{   font-size: 12px;
    font-family: var(--font-family);
    font-weight: bold;
    color: #0000007a;
}



.pbes__header-details_items .project3
{
    margin-right: 100px;
    background: white;
    border-radius: 5px 5px 0 0;
    width:200px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pbes__header-details_items .project3 img
{
   
    height: 50px;
    margin-right: 10px;
}
.pbes__header-details_items .project3 h4
{
    font-weight: bold;
    font-family: var(--font-family);
}
.pbes__header-details_items .project3 span
{
    font-weight: bold;
    font-family: var(--font-family);
    color:var(--color-text)
}
.pbes__header-details_items .project3 p
{   font-size: 12px;
    font-family: var(--font-family);
    font-weight: bold;
    color: #0000007a;
}
@media screen and (max-width:1024px)
{
    .pbes__header-details
    {
        display: none;
    }
}
@media  screen and (max-width:1024px) 
{
    .pbes__header-items_headings h2{
        font-size: 16px;
        line-height: 20px;
    }
    .pbes__header-items_headings h1{
        font-size: 32px;
        line-height: 36px;
    }
    .pbes__header-items_headings p{
        font-size: 14px;
        line-height: 18px;
    }
    .pbes__header_image img
    {
        max-width: 80%;
    }
   
}

@media screen and (max-width:880px) {
    .pbes__header-items_headings h2{
        font-size: 14px;
        line-height: 20px;
    }
    .pbes__header-items_headings h1{
        font-size: 28px;
        line-height: 36px;
    }
    .pbes__header-items_headings p{
        font-size: 12px;
        line-height: 18px;
    }
    .pbes__header-items
    {
        margin: 0;
    }
    .pbes__header-details
    {
        display: none;
    }
}
@media screen and (max-width:780px)
{
    .pbes__header-items_headings {
        margin: 0;
    }
    .pbes__header-items
    {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .pbes__header_image img
    {
        display: none;
    }
}
@media screen and (max-width:700px)
{

    .pbes__header_image img
    {
        display: none;
    }
}

@media screen and (max-width:300px) {
    
    .pbes__header-items_headings h2{
        font-size: 10px;
    }
    .pbes__header-items_headings h1{
        font-size: 30px;
        line-height: 46px;
    }
    .pbes__header-items_headings p{
        font-size: 9px;
        
    }
    .pbes__header-items_btn .Cart,
    .pbes__header-items_btn .Calc{
        font-size: 12px;
        width:100px;
        
    }
    .pbes__header-items
    {
        margin: 0;
    }
}