.pbes__preloader-head
{
  height: 100vh;
}
.pbes__preloader
{
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.pbes__container
{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20%;
   
}
.pbes__container .gif
{
    height:100px;
    width: 300px;
}
.pbes__container p
{
    color:var(--color-bl)
}