.pbes__navbar_up {
    display: flex;
    background-color: white;
}

.pbes__navbar_up-contianer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    width: 100%;
   
}

.pbes__navbar_up-container_items-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pbes__navbar_up-container_items-left img {
    width: 18px;
    margin-left: 50px;
}

.pbes__navbar_up-container_items-left p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 0.7rem;
    margin: 0;
    margin-left: 8px;
 
}


.pbes__navbar_up-container_items-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.pbes__navbar_up-container_items-right img
{
    width: 25px;
    margin-right: 40px;
}

@media  screen and (max-width:1024px) {
    .pbes__navbar_up-contianer {
        display: flex;
        flex-direction: column;
    }
  
    .pbes__navbar_up-container_items-left img
    {
       margin-left: 20px;
       
    }
    .pbes__navbar_up-container_items-left p 
    {
        margin-left: 5px;
    }
    .pbes__navbar_up-container_items-right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
    .pbes__navbar_up-container_items-right img
{
    width: 25px;
    margin-right: 20px;
}
 
}
@media  screen and (max-width:880px) {
    .pbes__navbar_up-contianer {
        display: flex;
        flex-direction: column;
    }
    .pbes__navbar_up-container_items-left
    {
        flex-direction: column;
    }
    .pbes__navbar_up-container_items-left img
    {
       margin-left: 0;
       margin-top: 10PX;
    }
    .pbes__navbar_up-container_items-left p 
    {
        margin-left: 2px;
    }
    .pbes__navbar_up-container_items-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
 
}