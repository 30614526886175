.pbes__navbar-home {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.pbes__navbar-home_items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.pbes__navbar-home_items .links {
    display: flex;
    color: #fff;
    margin-left: 300px;
}

.pbes__navbar-home_items nav li>* {
    margin-right: 20px;
    list-style-type: none;
    font-family: var(--font-family);
    font-weight: bolder;
    color: var(--color-button);
}

.pbes__navbar-home_items nav li>*:active {
    background-color: none;
}

.pbes__navbar-home_items .links li:hover {
    color: var(--color-button);
}

.pbes__navbar-home_items .links li:active {
    color: var(--color-text);
}

.pbes__navbar-home_login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pbes__navbar-home_login a,
pbes__navbar-menu_container a {
    color: #fff;
    list-style-type: none;
    margin-right: 20px;
    font-family: var(--font-family);

    padding-right: 10px;
    font-weight: bolder;
}

.pbes__nav-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.pbes__navbar-menu_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    text-align: end;
    background: rgb(61, 61, 61);
    position: absolute;
    padding: 2rem;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;

}

.pbes__navbar-menu_container ul {
    margin: 1rem 0;
}

@media screen and (max-width:1050px) {

    .pbes__navbar-home_items,
    .pbes__navbar-home_login {
        display: none;
    }

    .pbes__nav-menu {
        display: flex;
    }

    .pbes__navbar-home_logo img {
        max-width: 70%;
    }

    .pbes__navbar-menu_container {
        top: 20px;
        min-width: 100px;
        padding: 12px;
        border-radius: 4px;
        background-color: #fff;
    }

    .pbes__navbar-home_logo img {
        max-width: 50%;
    }

    nav ul {
        display: flex;
        flex-direction: column;
        background: transparent;
        justify-content: center;
        align-items: center;

    }

    nav ul li {


        padding: 5px;
    }

    .pbes__navbar-menu_container a {
        margin: 0;
        color: var(--color-text);
        font-weight: bold;

    }

}

@media screen and (max-width:700px) {
    .pbes__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width:550px) {
    .pbes__navbar {
        padding: 2rem;
    }

    .pbes__navbar-sign {
        display: none;
    }

    .pbes__navbar-menu_container {
        top: 20px;
        min-width: 100px;
        padding: 0;
        border-radius: 4px;
        background-color: #fff;
    }

    .pbes__navbar-home_logo img {
        max-width: 50%;
    }

    nav ul {
        display: flex;
        flex-direction: column;
        background: transparent;
        justify-content: center;
        align-items: center;

    }

    nav ul li {
        padding: 0;


    }

    .gpt3__navbar-menu_container a {
        margin: 0;
        color: var(--color-text)
    }

}